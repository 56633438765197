<template>
  <v-dialog
    v-model="dialogEditarEscuela.estatus"
    persistent
    max-width="1600"
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Editar Sistema</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( escuelas  , 'escuelas_ERP')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="orange"
          dark
          class="mr-2"
          @click="dialog = true"
          small
          tile
        >
          <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text class="pa-0">
		  	<v-tabs vertical v-model="tabs">

        	<!-- Menu de las opciones -->
		      <v-tab class="text-capitalize" v-for="(menu, i) in menus" :key="i">
		        <v-icon left>
		          {{ menu.icon }}
		        </v-icon>
		        {{ menu.text }}
		      </v-tab>

		      <!-- Portada -->
		      <v-tab-item>
          	<Portada  v-if="tabs == 0" :escuela="escuela"/> 
		      </v-tab-item>

		      <!-- Niveles -->
		      <v-tab-item>
            <Niveles  v-if="tabs == 1" :escuela="escuela"/>
		      </v-tab-item>

		      <!-- Lecciones -->
		      <v-tab-item>
            <Lecciones v-if="tabs == 2" :escuela="escuela"/>
		      </v-tab-item>

		      <!-- Diapositvas -->
		      <v-tab-item>
            <Diapositivas  v-if="tabs == 3" :escuela="escuela"/>
		      </v-tab-item>
		      
		    </v-tabs>

		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="$emit('initialize2')"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
        <v-pagination
          circle
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </v-card-actions>
		</v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from "vuex";
	// Componentes
	import Alerta from "@/components/alertas/Alerta.vue";
	import carga from "@/components/alertas/carga.vue";

	import validarErrores from "@/mixins/validarErrores";
	import funcionesExcel from "@/mixins/funcionesExcel";

	import Portada        from "@/components/sistemaweb/Portada.vue";
	import Niveles        from "@/components/sistemaweb/Niveles.vue";
	import Lecciones      from "@/components/sistemaweb/Lecciones.vue";
	import Diapositivas   from "@/components/sistemaweb/Diapositivas.vue";


	export default {

	  mixins: [ validarErrores, funcionesExcel ],

	  props: ['dialogEditarEscuela', 'escuela'],

	  components: {
	    Alerta,
	    carga,
	    Portada,
			Niveles,
			Lecciones,
			Diapositivas,
	  },

  	data: () => ({
    	tabs: 0, 
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      editedIndex: -1,

			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      escuelas: [],
      headers: [
        { text: 'ID'         , value: 'idescuelas'  },
        { text: 'escuela'    , value: 'escuela'     },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      menus: [
        { text: 'Portada'      , icon: 'mdi-image'                    },
        { text: 'Niveles'      , icon: 'mdi-order-numeric-ascending'  },
        { text: 'Lecciones'    , icon: 'mdi-gesture-tap'              },
        { text: 'Diapositivas' , icon: 'mdi-folder-image'             },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
    },

    watch: {

    },

    async created () {
    	console.log( this.escuela )
    },

    methods: {
      
    },
  }
</script>


