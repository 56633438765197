<template>
  <v-dialog
    v-model="dialogAddLeccion.estatus"
    persistent
    max-width="600"
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Administración de lecciones</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( lecciones  , 'escuelas_ERP')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="orange"
          dark
          class="mr-2"
          @click="dialog = true"
          small
          tile
        >
          <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row justify="end">
		  		<v-col cols="12" md="10">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>
		  	</v-row>
		    <v-row>
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="lecciones"
					    class="elevation-0"
					    :search="search"
					    :page.sync="page"
	            @page-count="pageCount = $event"
					    :mobile-breakpoint="100"
	            hide-default-footer
	            dense
					  >

					    <template v-slot:item.actions="{ item }">
					    	<v-btn 
					    		color="primary" 
					    		x-small
					    		@click="editItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small>mdi-magnify</v-icon>
					    	</v-btn>

					    	<v-btn 
					    		color="error" 
					    		x-small
					    		@click="deleteItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>
					    </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="$emit('initialize2')"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
        <v-pagination
          circle
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </v-card-actions>
		</v-card>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idlecciones }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
      		<label>Lección: </label>
          <v-text-field
            v-model="editedItem.leccion"
            label="Lección"
            filled
            hide-details
            single-line
            dense
          ></v-text-field>

          <v-col cols="12">
	          <div v-if="!file">
	            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
	              <div class="dropZone-info" @drag="onChange">
	                <span class="fa fa-cloud-upload dropZone-title"></span>
	                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
	                <div class="dropZone-upload-limit-info">
	                  <div>Extensión: png, jpg, jpeg, svg</div>
	                  <div>Tamaño máximo: 10 MB</div>
	                </div>
	              </div>
	              <input type="file" @change="onChange">
	            </div>
	          </div>

	          <!-- Botón para eliminar la imagen -->
	          <v-btn  v-else color="red" class="mb-2" dense @click="file = null, vistaPrevia = null, extension = ''" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
	          
	          <!-- Mostrar la imagen -->
	          <v-img :src="vistaPrevia.url" v-if="vistaPrevia && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia.extension )" contain max-height="300"/>
      		</v-col>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="close"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogAddLeccion','escuela', 'nivel'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idlecciones:0,
        idniveles:0,
        idarchivos:0,
				leccion:'',
        idescuelas:0,
        portada_niveles:'',
        portada_lecciones:'',
				deleted:0,
				fecha_creacion: null,
      },

      defaultItem: {
        idlecciones:0,
        idniveles:0,
        idarchivos:0,
				leccion:'',
        idescuelas:0,
        portada_niveles:'',
        portada_lecciones:'',
				deleted:0,
				fecha_creacion: null,
      },

			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      lecciones: [],
      headers: [
        { text: 'ID'         , value: 'idlecciones'  },
        { text: 'Leccion'      , value: 'leccion'     },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      pdf: null,
			dialogPDF: false,
	    
	    archivos:null,
	    vistaPrevia: null,
	    file: '',
	    dragging: false,
	    dialgImagen:false,
			url_servidor: '',
			fullscreen: false,
			pdf:null,
			dialogPDF: false,
			
			imagen: null,
			extension:'',
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar nivel' : 'Editar nivel'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
    },

    methods: {
      // initialize () {
      // 	this.cargar = true
      //   this.lecciones = []
      //   return this.$http.get('lecciones.get/' + this.nivel ).then(response=>{
      //   	this.lecciones = response.data.leccionesAll
      //   	this.cargar      = false
      //   }).catch( error =>{
      //     this.validarError( error.response.data.message )
      //   }).finally( () => { this.cargar = false })
      // },


      initialize() {
        this.cargar = true;
        this.lecciones = [];

        return axios.get('https://academicokpi.club/kpi/lecciones.get/' + this.nivel)
          .then(response => {
            this.lecciones = response.data.leccionesAll
            this.cargar = false;
          })
          .catch(error => {
            this.validarError(error.response.data.message);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      editItem (item) {
        this.editedIndex = this.lecciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.lecciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      // deleteItemConfirm () {
      // 	this.cargar = true
      //   this.editedItem.deleted = 1
      //   this.$http.put('leccion.update/' + this.editedItem.idlecciones, this.editedItem).then(response=>{
      //   	this.cargar = true
      //   	this.closeDelete()
      //   	this.initialize()
      //   }).catch( error =>{
      //     this.validarError( error.response.data.message )
      //   }).finally( () => { this.cargar = false })
      // },

      deleteItemConfirm() {
        this.cargar = true;
        // Lo mandapos por el EP
        this.editedItem.deleted = 1;
        
        axios.put('https://academicokpi.club/kpi/leccion.update/' + this.editedItem.idlecciones, this.editedItem)
          .then(response => {
            this.cargar = true;
            this.closeDelete();
            this.initialize();
          })
          .catch(error => {
            this.validarError(error.response.data.message);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      // async save () {
      // 	if(this.editedItem.escuela == ''){
      // 		this.validarErrorDirecto('Favor de llenar todos los campos')
      // 	}

      // 	let idarchivos = ''

	    // 	if( this.file ){
	    // 		idarchivos = await this.grabarImagen( )
	    // 	}

	    // 	this.editedItem.idarchivos = idarchivos
	    // 	this.editedItem.idescuelas = this.escuela.idescuelas
	    // 	this.editedItem.idniveles  = this.nivel

	    //   this.cargar = true

      //   if (this.editedIndex > -1) {
      //     this.$http.put('leccion.update/' + this.editedItem.idlecciones, this.editedItem).then(response=>{
      //     	this.validarSuccess( response.data.message )
      //   		this.close()
      //     	this.initialize()
	    //     }).catch( error =>{
      //     this.validarError( error.response.data.message )
      //   }).finally( () => { this.cargar = false })

      //   } else {
      //     this.$http.post('leccion.add', this.editedItem).then(response=>{
      //     	this.initialize()
      //   		this.close()
	    //     	this.cargar = false
	    //     }).catch( error =>{
      //     	this.validarError( error.response.data.message )
      //   	}).finally( () => { this.cargar = false })
      //   }
      // },

      async save() {
      if (this.editedItem.escuela == '') {
        this.validarErrorDirecto('Favor de llenar todos los campos');
      }

      let idarchivos = '';

      if (this.file) {
        idarchivos = await this.grabarImagen();
      }

      this.editedItem.idarchivos = idarchivos;
      this.editedItem.idescuelas = this.escuela.idescuelas;
      this.editedItem.idniveles  = this.nivel

      this.cargar = true;

      try {
        let response;
        if (this.editedIndex > -1) {
          response = await axios.put('https://academicokpi.club/kpi/leccion.update/' + this.editedItem.idlecciones, this.editedItem);
        } else {
          response = await axios.post('https://academicokpi.club/kpi/leccion.add', this.editedItem);
        }
        this.validarSuccess(response.data.message);
        this.close();
        this.initialize();
      } catch (error) {
        this.validarError(error.response.data.message);
      } finally {
        this.cargar = false;
      }
    },

      getBase64(file) {
	      var me = this
	      var reader = new FileReader();
	      reader.readAsDataURL(file);
	      reader.onload = function () {

	      	let extension = file.name.split('.')

	        me.vistaPrevia = {
	          url: reader.result, 
	          image_name: file.name,
	          extension: extension[extension.length - 1]
	        }
	      };
	    },
	 
			onChange(e) {
	      var files = e.target.files || e.dataTransfer.files;

	      if (!files.length) {
	        this.dragging = false;
	        return;
	      }
	      
	      this.createFile(files[0]);
	    },

	    createFile(file) {
	      if (file.size > 40000000) {
	        alert('please check file size no over 40 MB.')
	        this.dragging = false;
	        return;
	      }
	      
	      let extension = file.name.split('.')
	      
	      this.file = file;
	      this.extension = extension[extension.length - 1]
	      this.getBase64(this.file)
	      this.dragging = false;
	    },


	    // grabarImagen( ){
	    //   return new Promise((resolve,reject)=>{
	    //     let formData = new FormData();
	    //     formData.append("file"             , this.file);
	    //     formData.append("idescuelas"       , this.escuela.idescuelas);
	    //     formData.append("url"              , "");
	    //     formData.append("tipo_reproduccion", 0);
	    //     formData.append("interno"          , 1);
	    //     formData.append("descripcion"      , "portada nivel");

	    //     this.$http.post("archivo.add", formData).then( response => {
	    //     	console.log( response.data )
	    //     	this.vistaPrevia = null
	    //     	this.file        = null
	    //       resolve( response.data.id ) 
	    //     }).catch( error =>{
	    //       reject( error )
	    //     }).finally( () => { this.cargar = false })
	    //   })
	    // },

      grabarImagen() {

        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("idescuelas", this.escuela.idescuelas);
        formData.append("url", "");
        formData.append("tipo_reproduccion", 0);
        formData.append("interno", 1);
        formData.append("descripcion", "portada nivel");

        return axios.post("https://academicokpi.club/kpi/archivo.add", formData)
          .then((response) => {
            this.vistaPrevia = null;
            this.file = null;
            return Promise.resolve(response.data.id);
          })
          .catch((error) => {
            return Promise.reject(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      },

    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>