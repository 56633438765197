<template>
  <v-container fluid>
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">

          <!-- Titulo del componente -->
          <v-card-title class="text-subtitle-1">
            Editar diapositivas
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="getDiapositivas()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>


          <v-card-text>

            <!-- Apartado para ver el resultado -->
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="nivel"
                  :items="niveles"
                  label="Selecciona un nivel"
                  persistent-hint
                  item-text="nivel"
                  item-value="idniveles"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="leccion"
                  :items="lecciones"
                  label="Selecciona una lección"
                  persistent-hint
                  item-text="leccion"
                  item-value="idlecciones"
                >
                </v-autocomplete>
              </v-col>
              
              <v-col cols="12" md="4" lg="3" >
                <!-- Botón para gregar un nivel -->
                <v-btn 
                  color="primary"
                  tile
                  small
                  class="mb-2"
                  @click="dialogAddDiapositiva.estatus = true"
                >
                  <v-icon small left>mdi-plus</v-icon>
                  Admin Diapositivas
                </v-btn>
              </v-col>

              <!-- CONTENIDO -->
              <v-col cols="12" md="8" v-if=" leccion ">
                
                <!-- Mostrar resultado -->
                <v-card class="elevation-0 transparent" id="create" v-if="diapositivas.length">
                  <v-img 
                    :src="url_servidor + diapositivas[ numDiapositiva ].urlPortada" 
                    v-if="escuela.portada_lecciones" 
                    contain
                    width="100%"
                    class="white--text align-end justify-center"
                  >
	                  <!-- Para cuando hay un video -->
	                  <v-card
					          	v-if="diapositivas[ numDiapositiva ].extension == 'mp4' && verVideo"
				              style="width: 100%; height: auto"
				              class="elevation-0 transparent video_contenedor"
				            >
				              <video controls style="width: 80%; height: 100%" :id="diapositivas[ numDiapositiva ].urlArchivo">
				                <source
				                	v-if="verVideo"
				                  :src="url_servidor + diapositivas[ numDiapositiva ].urlArchivo"
				                  type="video/mp4"
				                />
				              </video>
				            </v-card>
                  
                  </v-img>


                  <!-- Botones de acción -->
					        <v-btn
					        	v-if="diapositivas[ numDiapositiva ].extension == 'mp3'"
					        	class="boton-play"
					          color="black"
					          dark
					          fab
					          small
					          @click="crearTemplateSonido( diapositivas[ numDiapositiva ].urlArchivo )"
					        >
					          <v-icon v-text="audioPlaying  ? 'mdi-pause' : 'mdi-play'">
					          </v-icon>
					        </v-btn>

                  <!-- Botones de acción -->
                	<v-speed-dial
							      bottom
							      right
							      small
							      transition="slide-y-reverse-transition"
							    >
							      <template v-slot:activator>
							        <v-btn
							          color="black"
							          dark
							          fab
							          small
							          @click="next"
							        >
							          <v-icon >
							            mdi-skip-next
							          </v-icon>
							        </v-btn>
							      </template>
							    </v-speed-dial>

							    <!-- Anterior -->
							    <v-speed-dial
							      bottom
							      right
							      small
							      transition="slide-y-reverse-transition"
							      class="mr-16"
							    >
							      <template v-slot:activator>
							        <v-btn
							          color="black"
							          dark
							          fab
							          small
							          @click="previous"
							        >
							          <v-icon >
							            mdi-skip-previous
							          </v-icon>
							        </v-btn>
							      </template>
							    </v-speed-dial>
                </v-card>
              </v-col>

            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Componente para administrar los niveles -->
    <NewDiapositivas v-if="dialogAddDiapositiva.estatus" :dialogAddDiapositiva="dialogAddDiapositiva" :escuela="escuela" @initialize2="getDiapositivas()" :nivel="nivel" :leccion="leccion"/>


    <!-- Dialog de carga -->
    <Alerta    v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga     v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import NewDiapositivas from "@/components/sistemaweb/NewDiapositivas.vue";


import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  components: {
    Alerta,
    carga,
    NewDiapositivas,
  },

  mixins: [validarErrores, funcionesExcel],

  props: ['escuela'],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    pdf: null,
    dialogPDF: false,
    
    archivos:null,
    vistaPrevia: null,
    file: '',
    dragging: false,
    dialgImagen:false,
    url_servidor: '',
    fullscreen: false,
    pdf:null,
    dialogPDF: false,
    
    imagen: null,
    extension:'',

    dialogAddDiapositiva:{
      estatus: false,
    },

    lecciones:[],
    leccion: null,

    diapositivas:[],

    niveles:[],
    nivel: null,

    numDiapositiva: 0,
    audio: null,
    audioPlaying: false,
    verVideo: false,

  }),

  watch:{
    nivel( value ){
      if( value ){ this.getLecciones() }
    },

  	leccion( value ){
      if( value ){ this.getDiapositivas() }
    }
  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
  },

  async created() {
    this.getNiveles()
    this.getLecciones()
    // this.url_servidor = axios.defaults.baseURL + "galeria/";
    this.url_servidor = 'https://academicokpi.club/kpi/galeria/'
  },

  methods: {

  	// getDiapositivas ( ){
  	// 	this.cargar = true
    //   this.diapositivas                 = []
    //   this.dialogAddDiapositiva.estatus = false

    //   return this.$http.get('diapositivas.get/' + this.leccion ).then(response=>{
    //     this.diapositivas = response.data.diapositivas
    //     this.cargar           = false
    //   }).catch( error =>{
    //     this.validarError( error.response.data.message )
    //   }).finally( () => { this.cargar = false })
  	
  	// },

    getDiapositivas() {
      this.cargar = true;
      this.diapositivas = []
      this.dialogAddDiapositiva.estatus = false

      return axios.get('https://academicokpi.club/kpi/diapositivas.get/' + this.leccion)
        .then(response => {
          this.diapositivas = response.data.diapositivas
          this.cargar = false;
        })
        .catch(error => {
          this.validarError(error.response.data.message);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    // getLecciones(){
    //   this.cargar = true
    //   this.lecciones = []
    //   return this.$http.get('lecciones.get/' + this.nivel ).then(response=>{
    //     this.lecciones = response.data.leccionesAll
    //     this.cargar           = false
    //   }).catch( error =>{
    //     this.validarError( error.response.data.message )
    //   }).finally( () => { this.cargar = false })

    // },


    getLecciones() {
      this.cargar = true;
      this.lecciones = []

      return axios.get('https://academicokpi.club/kpi/lecciones.get/' + this.nivel)
        .then(response => {
          this.lecciones = response.data.leccionesAll
          this.cargar = false;
        })
        .catch(error => {
          this.validarError(error.response.data.message);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    // getNiveles( ){
    //   this.cargar = true
    //   this.niveles = []
    //   this.dialogAddDiapositiva.estatus = false
    //   return this.$http.get('nivel.get/' + this.escuela.idescuelas ).then(response=>{
    //     this.niveles = response.data.nivelesAll
    //     this.cargar      = false
    //   }).catch( error =>{
    //     this.validarError( error.response.data.message )
    //   }).finally( () => { this.cargar = false })
    // },


    getNiveles() {
      this.cargar = true;
      this.niveles = []
      this.dialogAddDiapositiva.estatus = false

      return axios.get('https://academicokpi.club/kpi/nivel.get/' + this.escuela.idescuelas)
        .then(response => {
          this.niveles = response.data.nivelesAll
          this.cargar = false;
        })
        .catch(error => {
          this.validarError(error.response.data.message);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    // Función para darle siguiente a las diapositivas
    next ( ){
     
    	// Validamos que si haya diapositivas
    	if( this.diapositivas.length ){
     
    		// Validamos que la diapositiva actual sea menor a la longitud de diapositivas
    		if( this.numDiapositiva < this.diapositivas.length - 1 ){
    			this.numDiapositiva ++

    			const { extension , urlArchivo, tipo_reproduccion } = this.diapositivas[this.numDiapositiva]

          console.log('extension',extension ,'urlArchivo', urlArchivo,'tipo_reproduccion', tipo_reproduccion )

    			this.verVideo = false

    			if( extension == 'mp4'){ setTimeout(() => {this.verVideo = true}, 100 )}
  
  				// Reproduce el audio
    			if (this.audioPlaying) { this.audio.pause(); this.audioPlaying = !this.audioPlaying; }
    
    			// Validar, si el archivo que sigue tiene un audio y se debe reproducir de manera automática
    			if( extension == 'mp3' && tipo_reproduccion == 1 ){ 

    				// Creamos el audio
						this.audio = new Audio( this.url_servidor + urlArchivo );

						/*
							hemos añadido un método llamado handleAudioEnded que se ejecuta cuando el evento ended del objeto Audio se dispara, es decir, cuando el audio ha terminado de reproducirse. 
						*/
						this.audio.addEventListener('ended', this.handleAudioEnded);

						// Lo reproducimos
    				this.crearTemplateSonido( urlArchivo ) 
    			} 
    		}
    	}
    },

    // Función para darle anterior a las diapositivas
		previous ( ) {
			// Validamos que si haya diapositivas
    	if( this.diapositivas.length ){
    		// Validamos que la diapositiva actual sea menor a la longitud de diapositivas
    		if( this.numDiapositiva > 0 ){
    			this.numDiapositiva  -- 

    			const { extension , urlArchivo, tipo_reproduccion } = this.diapositivas[this.numDiapositiva]

    			this.verVideo = false

    			if( extension == 'mp4'){ setTimeout(() => {this.verVideo = true}, 100 )}

  				
  				// Reproduce el audio
    			if (this.audioPlaying) { this.audio.pause(); this.audioPlaying = !this.audioPlaying; }
    			
    			// Validar, si el archivo que sigue tiene un audio y se debe reproducir de manera automática
    			if( extension == 'mp3' && tipo_reproduccion == 1 ){ 

    				// Creamos el audio
						this.audio = new Audio( this.url_servidor + urlArchivo );

						/*
							hemos añadido un método llamado handleAudioEnded que se ejecuta cuando el evento ended del objeto Audio se dispara, es decir, cuando el audio ha terminado de reproducirse. 
						*/
						this.audio.addEventListener('ended', this.handleAudioEnded);

						// Lo reproducimos
    				this.crearTemplateSonido( urlArchivo ) 
    			}

    		}
    	}
		},

		crearTemplateSonido( urlArchivo ){


      // Reproduce el audio
      if (this.audioPlaying) {
        
        this.audio.pause(); // Pausa el audio si ya está reproduciendo
      } else {

        this.audio.play(); // Reproduce el audio si no está reproduciendo
      }

      this.audioPlaying = !this.audioPlaying;

    },

    handleAudioEnded() {
      this.audioPlaying = false; // Cambia el estado de reproducción cuando el audio termina
    },

  },
};
</script>
<style scoped>
	#create .v-speed-dial {
    position: absolute;
  }

  #create .v-btn--floating {
    position: relative;
  }


  .boton-play{
	  position: absolute; /* Posición absoluta dentro del contenedor */
	  bottom: 15px; /* Coloca el botón en la parte inferior */
	  left: 50%; /* Alinea el botón en el centro horizontal */
	  transform: translateX(-50%); /* Centra el botón horizontalmente */
	  padding: 10px 20px;
	  background-color: #007bff;
	  color: white;
	  border: none;
	  cursor: pointer;
	}


	.video_contenedor{
		position: absolute; /* Posición absoluta dentro del contenedor */
	  bottom: 15%; /* Coloca el botón en la parte inferior */
	  left: 55%; /* Alinea el botón en el centro horizontal */
	  transform: translateX(-50%); /* Centra el botón horizontalmente */
	  padding: 10px 20px;
	  background-color: #007bff;
	  color: white;
	  border: none;
	  cursor: pointer;
	}
</style>