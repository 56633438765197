<template>
  <v-container fluid>
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">

          <!-- Titulo del componente -->
          <v-card-title class="text-subtitle-1">
            Editar portada del sistema
            <v-spacer></v-spacer>


            <!-- Botoones de acción -->
            <v-btn color="success" @click="save()" small tile class="mr-2" v-if="vistaPrevia">
              <v-icon small left>mdi-content-save</v-icon>
              Guardar
            </v-btn>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>


          <v-card-text>

            <!-- Apartado para grabar la imagen -->
            <v-row>
              <v-col cols="12" md="6">
                <div v-if="!file">
                  <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                    <div class="dropZone-info" @drag="onChange">
                      <span class="fa fa-cloud-upload dropZone-title"></span>
                      <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                      <div class="dropZone-upload-limit-info">
                        <div>Extensión: png, jpg, jpeg, svg</div>
                        <div>Tamaño máximo: 10 MB</div>
                      </div>
                    </div>
                    <input type="file" @change="onChange">
                  </div>
                </div>

                <!-- Botón para eliminar la imagen -->
                <v-btn  v-else color="red" class="mb-2" dense @click="file = null, vistaPrevia = null, extension = ''" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
                
                <!-- Mostrar la imagen -->
                <v-img :src="vistaPrevia.url" v-if="vistaPrevia && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia.extension )" contain max-height="300"/>
              </v-col>
            </v-row>

            <!-- Apartado para ver el resultado -->
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="nivel"
                  :items="niveles"
                  label="Selecciona un nivel"
                  persistent-hint
                  item-text="nivel"
                  item-value="idniveles"
                >
                </v-autocomplete>
              </v-col>
              
              <v-col cols="12" md="6" >
                <!-- Botón para gregar un nivel -->
                <v-btn 
                  color="primary"
                  tile
                  small
                  class="mb-2"
                  @click="dialogAddLeccion.estatus = true"
                >
                  <v-icon small left>mdi-plus</v-icon>
                  Admin Lecciones
                </v-btn>
              </v-col>
              <v-col cols="12">
                


                <!-- Mostrar resultado -->
                <v-card class="elevation-0 transparent">
                  <v-img 
                    :src="url_servidor + escuela.portada_lecciones" 
                    v-if="escuela.portada_lecciones" 
                    contain
                    width="100%"
                    class="white--text align-end justify-center"
                  >
                  <v-card-text class="texto_contenedor align-center text-center" align="center">
                    <div class="cuadros-container">
                      <v-card class="elevation-0 transparent align-center cuadro" v-for="(leccion, i ) in lecciones" :key="i" v-if="i < 5">
                        <v-img :src="url_servidor + leccion.archivo" contain max-width="200" class="text-center"/>
                      </v-card>
                    </div>

                    <div class="cuadros-container">
                      <v-card class="elevation-0 transparent align-center cuadro" v-for="(leccion, i ) in lecciones" :key="i" v-if="i >= 5 && i < 10">
                        <v-img :src="url_servidor + leccion.archivo" contain max-width="200" class="text-center"/>
                      </v-card>
                    </div>

                    <div class="cuadros-container">
                      <v-card class="elevation-0 transparent align-center cuadro" v-for="(leccion, i ) in lecciones" :key="i" v-if="i >= 10 && i < 15">
                        <v-img :src="url_servidor + leccion.archivo" contain max-width="200" class="text-center"/>
                      </v-card>
                    </div>

                    <div class="cuadros-container">
                      <v-card class="elevation-0 transparent align-center cuadro" v-for="(leccion, i ) in lecciones" :key="i" v-if="i >= 15 && i < 20">
                        <v-img :src="url_servidor + leccion.archivo" contain max-width="200" class="text-center"/>
                      </v-card>
                    </div>

                  </v-card-text>
                  </v-img>
                </v-card>
              </v-col>

            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Componente para administrar los niveles -->
    <NewLecciones v-if="dialogAddLeccion.estatus" :dialogAddLeccion="dialogAddLeccion" :escuela="escuela" @initialize2="getNiveles()" :nivel="nivel"/>


    <!-- Dialog de carga -->
    <Alerta    v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga     v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import NewLecciones from "@/components/sistemaweb/NewLecciones.vue";


import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  components: {
    Alerta,
    carga,
    NewLecciones,
  },

  mixins: [validarErrores, funcionesExcel],

  props: ['escuela'],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    pdf: null,
    dialogPDF: false,
    
    archivos:null,
    vistaPrevia: null,
    file: '',
    dragging: false,
    dialgImagen:false,
    url_servidor: '',
    fullscreen: false,
    pdf:null,
    dialogPDF: false,
    
    imagen: null,
    extension:'',

    dialogAddLeccion:{
      estatus: false,
    },

    lecciones:[],

    niveles:[],
    nivel: null,

  }),

  watch:{
    nivel( value ){
      if( value ){ this.getLecciones() }
    }
  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
  },

  async created() {
    this.getNiveles()
    // this.url_servidor = axios.defaults.baseURL + "galeria/";
    this.url_servidor = 'https://academicokpi.club/kpi/galeria/'
  },

  methods: {

    // getLecciones(){
    //   this.cargar = true
    //   this.lecciones = []
    //   return this.$http.get('lecciones.get/' + this.nivel ).then(response=>{
    //     this.lecciones = response.data.leccionesAll
    //     this.cargar           = false
    //   }).catch( error =>{
    //     this.validarError( error.response.data.message )
    //   }).finally( () => { this.cargar = false })

    // },

    getLecciones() {
      this.cargar = true;
      this.lecciones = []

      return axios.get('https://academicokpi.club/kpi/lecciones.get/' + this.nivel)
        .then(response => {
          this.lecciones = response.data.leccionesAll
          this.cargar = false;
        })
        .catch(error => {
          this.validarError(error.response.data.message);
        })
        .finally(() => {
          this.cargar = false;
        });
    },
    
    async save(){

      let nombre_archivo = ''

      if( this.file ){
        nombre_archivo = await this.grabarImagen( )
      }

      this.escuela.portada_lecciones = nombre_archivo

      this.cargar = true

      return this.$http.put('escuela.update/' + this.escuela.idescuelas, this.escuela ).then(response=>{
        this.validarSuccess('Datos cargados correctamente')
        this.cargar           = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    // getNiveles( ){
    //   this.cargar = true
    //   this.niveles = []
    //   this.dialogAddLeccion.estatus = false
    //   return this.$http.get('nivel.get/' + this.escuela.idescuelas ).then(response=>{
    //     this.niveles = response.data.nivelesAll
    //     this.cargar      = false
    //   }).catch( error =>{
    //     this.validarError( error.response.data.message )
    //   }).finally( () => { this.cargar = false })
    // },


    getNiveles() {
      this.cargar = true;
      this.niveles = []
      this.dialogAddLeccion.estatus = false

      return axios.get('https://academicokpi.club/kpi/nivel.get/' + this.escuela.idescuelas)
        .then(response => {
          this.niveles = response.data.nivelesAll
          this.cargar = false;
        })
        .catch(error => {
          this.validarError(error.response.data.message);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    getBase64(file) {
      var me = this
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {

        let extension = file.name.split('.')

        me.vistaPrevia = {
          url: reader.result, 
          image_name: file.name,
          extension: extension[extension.length - 1]
        }
      };
    },
 
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    },

    createFile(file) {
      if (file.size > 40000000) {
        alert('please check file size no over 40 MB.')
        this.dragging = false;
        return;
      }
      
      let extension = file.name.split('.')
      
      this.file = file;
      this.extension = extension[extension.length - 1]
      this.getBase64(this.file)
      this.dragging = false;
    },


    // grabarImagen( ){
    //   return new Promise((resolve,reject)=>{
    //     let formData = new FormData();
    //     formData.append("file", this.file);

    //     this.$http.post("galeria.sistema", formData).then( response => {
    //       this.vistaPrevia = null
    //       this.file        = null
    //       resolve( response.data.nombre ) 
    //     }).catch( error =>{
    //       reject( error )
    //     }).finally( () => { this.cargar = false })
    //   })
    // },

    grabarImagen() {

		let formData = new FormData();
		formData.append("file", this.file);

		return axios.post("https://academicokpi.club/kpi/galeria.sistema", formData)
			.then((response) => {
				this.vistaPrevia = null;
				this.file = null;
				return Promise.resolve(response.data.nombre);
			})
			.catch((error) => {
				return Promise.reject(error);
			})
			.finally(() => {
				this.cargar = false;
			});
		},

  },
};
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }

  /* Estilos del contenedor de cuadros */
  .cuadros-container {
    display: flex;
    justify-content: space-between; /* Distribuye los cuadros equitativamente */
    align-items: center; /* Alinea verticalmente los cuadros */
    flex-wrap: nowrap; /* Evita que los cuadros se envuelvan a la siguiente línea */
    padding: 10px;
  }

  /* Estilos de los cuadros */
  .cuadro {
    width: 18%; /* Ajusta el ancho de los cuadros según el espacio disponible */
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .texto_contenedor{
    justify-content: center; /* Centrado horizontal */
    align-items: center; /* Centrado vertical */
    max-width: 100%;
    padding-left: 12%;
    padding-right: 12%;
  }
</style>